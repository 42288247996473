import { useState } from "react";
import { NavHashLink } from "@xzar90/react-router-hash-link";
import { BrowserRouter } from "react-router-dom";

// import LogoSVG from "./assets/images/logo.svg";
// import footerLogo from "./assets/images/footer-logo.png";

import landingBg from "./assets/images/landing-bg.png";
import topBg from "./assets/images/top-bg.png";
import bottomBg from "./assets/images/bottom-bg.png";

import styles from "./App.module.scss";

const points = [
  {
    title: "Automated Bid Optimization",
    content:
      "Let our app handle the intricacies of bid adjustments automatically, ensuring your PPC strategy is always fine-tuned for profitability.",
  },
  {
    title: "Effortless Profit Maximization",
    content:
      "Streamline your Amazon PPC bids effortlessly, allowing you to focus on your business while our app works to maximize profits and increase sales.",
  },
  {
    title: "User-Friendly Interface",
    content:
      "Navigate our intuitive interface with ease, making it simple to set up and manage your automated PPC campaigns without the need for constant monitoring.",
  },
  {
    title: "Hassle-Free Operation",
    content:
      "Say goodbye to manual adjustments and monitoring. Our app handles the complexities, saving you time and effort in managing your PPC advertising.",
  },
];

function App() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  // const [openBanner, setOpenBanner] = useState(false);

  const postMail = () => {
    fetch(
      "https://emailsenderprod.azurewebsites.net/api/emailSenderProduction",
      {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fromEmail: "admin@shmili.com",
          toEmail: "shmilibreuer@gmail.com",
          subject: "Email contact from profitfirstppc.com",
          data: [
            { label: "Name", value: name },
            { label: "Email", value: email },
            { label: "Business Name", value: businessName },
            { label: "Phone", value: phone },
            { label: "Message", value: message },
          ],
        }),
      }
    )
      .then((response) => response)
      .then((data) => {
        setName("");
        setBusinessName("");
        setEmail("");
        setEmail("");
        setMessage("");
        // console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className={styles.app}>
      <BrowserRouter>
        <header className={styles.header}>
          <div className={`df aic ${styles.headerContent}`}>
            <p className={`ffmsb fs30`}>Profit First PPC</p>

            <div className={`mla df aic m-r-20 ${styles.navBtns}`}>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://app.profitfirstppc.com/login"
                className={`df acc ffmsb fs18 ${styles.featuresButton}`}
              >
                Login
              </a>
              <NavHashLink
                to="/#features"
                className={`df acc ffmsb fs18 ${styles.featuresButton}`}
              >
                Features
              </NavHashLink>
              <NavHashLink
                to="/#contact"
                className={`df acc ffmsb fs18 ${styles.fullButton}`}
              >
                Contact Us
              </NavHashLink>
            </div>
          </div>
        </header>

        <section className={styles.landing}>
          <div className={`df aic ${styles.landingContent}`}>
            <div className={styles.leftSec}>
              <h1 className={`ffmsb fs50`}>
                The most innovative PPC spend management software
              </h1>
              <div className={`m-b-40 ${styles.margin}`}></div>
              <h4 className={`ffmr fs18`}>
                Profit First PPC transforms Amazon PPC management, empowering
                sellers to control spend, maximize profits, and achieve
                precision in their strategy. Say goodbye to budget uncertainties
                and welcome a new era of profitability with real-time insights!
              </h4>

              <div className={`df m-t-80 ${styles.btnsWrpr}`}>
                {/* <button className={`ffmsb fs18 m-r-30 ${styles.contact}`}>
                  Contact Us
                </button> */}

                <NavHashLink
                  to="/#contact"
                  className={`df acc ffmsb fs18 m-r-30 ${styles.contact}`}
                >
                  Contact Us
                </NavHashLink>
                <NavHashLink
                  to="/#features"
                  className={`df acc ffmsb fs18 m-r-30 ${styles.learn}`}
                >
                  Learn More
                </NavHashLink>
              </div>
            </div>
          </div>
          <img className={styles.landingImage} src={landingBg} alt="" />
        </section>

        {/* <div className={`p-t-60 p-b-60 ${styles.topBanner}`}>
          <h3 className={`tac ffmr fs14 m-b-20`}>
            Select your limited time offer
          </h3>
          <div className={`df aic ${styles.bannerContent}`}>
            <div className={`df ffc aic tac ffmr fs14 ${styles.bannerSec}`}>
              <h5 className={`ffmsb fs16`}>
                6 Months full money back guaranty
              </h5>
              <div className={`m-t-20 ${styles.offerDetails} ${styles.open}`}>
                <p>
                  Sign up and start using our software for your facility and we
                  are sure you will love it. In case you are not fully satisfied
                  with our software we will give you full refund for your money.
                  Offer valid for 6 months from first staring to use our
                  software.
                </p>
              </div>
              <NavHashLink
                to="/#contact"
                className={`ffmsb fs14 m-t-25 ${styles.gsButton}`}
              >
                Get started
              </NavHashLink>
            </div>

            <div className={`df ffc aic tac ffmr fs14 ${styles.bannerSec}`}>
              <h5 className={`ffmsb fs16`}>Get first 3 months totally free</h5>
              <div className={`m-t-20 ${styles.offerDetails} ${styles.open}`}>
                <p>
                  Sign up and start using our software for your self storage
                  facility and pay nothing for the first three months, you won't
                  pay anything for the first three months including onboarding
                  fees, monthly fees and services fees, it is fully free.
                </p>
              </div>
              <NavHashLink
                to="/#contact"
                className={`ffmsb fs14 m-t-25 ${styles.gsButton}`}
              >
                Get started
              </NavHashLink>
            </div>
          </div>

         
        </div> */}

        <section className={`${styles.features}`} id="features">
          <img className={styles.topBg} src={topBg} alt="" />
          <img className={styles.bottomBg} src={bottomBg} alt="" />
          <div className={`${styles.featuresContent}`}>
            <h2 className={`ffmsb fs35 m-t-80 m-b-60`}>Key Advantages</h2>

            <div className={styles.featuresList}>
              {points.map((point) => (
                <div className={`df aic p-r-50 ${styles.feature}`}>
                  <div>
                    <h5 className={`ffmm fs14`}>{point.title}</h5>
                    <p className={`ffmr fs12 ${styles.featureContent}`}>
                      {point.content}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className={styles.contact} id="contact">
          <div className={`${styles.contactContent}`}>
            <h3 className={`ffmsb fs35 tac`}>Request Demo</h3>
            <div className={`${styles.contactForm}`}>
              <div className={`m-b-20 ${styles.formLine}`}>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  style={{ width: "calc(50% - 10px)" }}
                  className={`ffmr fs16`}
                  placeholder="Name"
                />
                <input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  style={{ width: "calc(50% - 10px)" }}
                  className={`ffmr fs16`}
                  placeholder="Phone"
                />
              </div>

              <div className={`df ${styles.formLine}`}>
                <div
                  style={{ width: "calc(50% - 10px)" }}
                  className={styles.secondLine}
                >
                  <div style={{ width: "100%" }}>
                    <input
                      value={businessName}
                      onChange={(e) => setBusinessName(e.target.value)}
                      style={{ width: "100%" }}
                      className={`ffmr fs16 m-b-20`}
                      placeholder="Business Name"
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{ width: "100%" }}
                      className={`ffmr fs16`}
                      placeholder="Email *"
                    />
                  </div>
                </div>
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className={`ffmr fs16`}
                  placeholder="Message"
                  style={{
                    width: "calc(50% - 10px)",
                    marginLeft: "20px",
                    padding: "18px 24px",
                  }}
                ></textarea>
              </div>
              <div className={`m-t-20 df`}>
                <button
                  disabled={!email.length}
                  onClick={postMail}
                  className={`mla ${styles.sendButton}`}
                >
                  SEND MESSAGE
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className={`${styles.footer}`}>
          <div className={`df ${styles.footerContent}`}>
            <div>
              <p className={`ffmsb fs14`}>Email us</p>
              <a
                className={`ffmr fs14 ${styles.emailLink}`}
                href="mailto:info@profitfirstppc.com"
              >
                info@profitfirstppc.com
              </a>
            </div>

            <div className={`mla`}>
              <p className={`ffmsb fs30`}>Profit First PPC</p>
            </div>
          </div>
        </section>
      </BrowserRouter>
    </div>
  );
}

export default App;
